
import { defineComponent, ref } from "vue"
import FilterBaseV2, { IEmitRequestFlow, IEmitGlobalFields } from "@/layout/header/components/FilterBaseV2.vue"
import { useLoaderStore } from "@/store/LoaderStore"

export default defineComponent({
  name: "FilterCategoriesListPage",
  components: {
    FilterBaseV2,
  },
  setup(props, { emit }) {
    /** Variables */
    const isActiveModal  = ref(false)
    const isCloseModal = ref(false)
    const loaderStore = useLoaderStore()

    /** Functions */
    function handleChangesGlobalFields(emit: IEmitGlobalFields) {
      // console.log("EmitGlobalFields: ", emit)
    }

    async function handleRequestFlow(emit: IEmitRequestFlow) {
      // console.log("EmitRequestFlow: ", emit)

      isCloseModal.value = false
      loaderStore.open()
      if(!emit.loading) {
        isCloseModal.value = true
        loaderStore.close()
      }
      if(emit.error)
        loaderStore.close()
    }

    /** Life Cycles */

    return {
      isActiveModal,
      isCloseModal,
      handleChangesGlobalFields,
      handleRequestFlow,
    }
  },
})
